// Breadcrumbs

$breadcrumb-bg: transparent !default;
$breadcrumb-active-color: $primary !default;
$breadcrumb-border-radius: $border-radius !default;
$breadcrumb-font-size: null !default;

// Divider

$breadcrumb-divider: quote('/') !default;
$breadcrumb-divider-color: $body-color !default;

// Padding

$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: 0.5rem !default;

// Margin

$breadcrumb-margin-bottom: 1rem !default;
