// Cards

$card-bg: $white !default;
$card-color: null !default;
$card-height: null !default;
$card-img-overlay-padding: 1.25rem !default;
$card-group-margin: $grid-gutter-width * 0.5 !default;
$card-deck-margin: $card-group-margin !default;

// Spacer

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;

// Border

$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;

// Cap

$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;

// Columns

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;
