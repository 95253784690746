@import './components/index.scss';

.web-layout-content {
  min-height: 90vh;
  padding: 0;
  letter-spacing: $letter-spacing;
}

// Custom classes

.element-behind {
  z-index: -1;
}

.element-forward {
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.highlighted-text {
  &-primary {
    @include highlighted-text($primary-lighten);
  }
  &-secondary {
    @include highlighted-text($secondary-lighten);
  }
  &-info {
    @include highlighted-text($info-lighten);
  }
}

p.bullet-element {
  line-height: 2rem;

  &::before {
    content: '';
    border-radius: 50%;
    background-color: lighten($primary, 50%);
    display: inline-block;
    margin-right: 1rem;
    padding: 0.35rem;
  }
}

p.bullet-element-child {
  margin-left: 2rem;
}

.grid-two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;

  @include tablet {
    display: block;
  }
}

// IMAGES

.self-portrait {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include mobile {
    justify-content: center;
  }

  div {
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 575px), (min-width: 768px) and (max-width: 991px) {
      width: 65%;
    }

    @media (min-width: 576px) and (max-width: 767px) {
      width: 50%;
    }

    img {
      width: 100%;
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

      @media (max-width: 797px) {
        margin-top: 2rem;
      }
    }
  }
}
