// Label

$label-margin-bottom: 0.5rem !default;

// Input

$input-bg: $white !default;
$input-color: $body-color !default;
$input-box-shadow: $box-shadow-inset;
$input-placeholder-color: darken($border-color, 5%) !default;
$input-plaintext-color: $input-placeholder-color !default;
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Border

$input-border-color: $border-color !default;
$input-border-width: $border-width * 1.5 !default;
$input-btn-border-width: $input-border-width !default;

$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-lg !default;

// Disabled

$input-disabled-bg: $gray-200 !default;
$input-disabled-border-color: $input-border-color !default;

// Font

$input-font-family: null !default;
$input-font-size: $font-size-sm !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $line-height-base !default;

$input-btn-font-family: $input-font-family !default;
$input-btn-font-size: $input-font-size !default;
$input-btn-line-height: $input-line-height !default;

// Padding

$input-padding-y: 0.375rem !default;
$input-padding-x: 0.75rem !default;
$input-btn-padding-y: $input-padding-y !default;
$input-btn-padding-x: $input-padding-x !default;

// Height

$input-height-border: $input-border-width * 2 !default;
$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5) !default;
$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;

// Focus

$focus-color: lighten($primary, 45%);

$input-focus-bg: $input-bg !default;
$input-focus-border-color: transparent !default;
$input-focus-width: 0.15rem !default;
$input-focus-color: $input-color !default;
$input-focus-box-shadow: 0 0 0 0.15rem $focus-color !default;

$input-btn-focus-width: $input-focus-width !default;
$input-btn-focus-color: $focus-color !default;
$input-btn-focus-box-shadow: 0 0 0 0.15rem $input-btn-focus-color !default;

// Group

$input-group-addon-padding-y: $input-padding-y !default;
$input-group-addon-padding-x: $input-padding-x !default;
$input-group-addon-font-weight: $input-font-weight !default;
$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;

// Size `sm`

$input-padding-y-sm: 0.25rem !default;
$input-padding-x-sm: 0.5rem !default;
$input-font-size-sm: $font-size-sm !default;
$input-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-sm: $input-padding-y-sm !default;
$input-btn-padding-x-sm: $input-padding-x-sm !default;
$input-btn-font-size-sm: $input-font-size-sm !default;
$input-btn-line-height-sm: $input-line-height-sm !default;

$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;

// Size `lg`

$input-padding-y-lg: 0.5rem !default;
$input-padding-x-lg: 1rem !default;
$input-font-size-lg: $font-size-lg !default;
$input-line-height-lg: $line-height-lg !default;

$input-btn-padding-y-lg: $input-padding-y-lg !default;
$input-btn-padding-x-lg: $input-padding-x-lg !default;
$input-btn-font-size-lg: $input-font-size-lg !default;
$input-btn-line-height-lg: $input-line-height-lg !default;

$input-height-lg: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;
