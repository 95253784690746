// Buttons

$btn-box-shadow: $box-shadow-inset;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Border

$btn-border-width: $input-btn-border-width !default;
$btn-border-radius: $border-radius !default;

// Disabled

$btn-disabled-opacity: 0.5 !default;

// Font

$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-font-weight: $font-weight-normal !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

// Padding

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;

// Link

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $body-color !default;

// Focus

$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;

// Active

$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

// Size `sm`

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;
$btn-border-radius-sm: $border-radius-sm !default;

// Size `lg`

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;
$btn-border-radius-lg: $border-radius-lg !default;
