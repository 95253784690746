.language-toggle {
  border-radius: 6px;
  border: solid $primary;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: $primary;
  position: absolute;
  right: 22vw;
  width: 70px;

  &-item {
    font-size: $small-font-size;
    padding: 0 0.25rem;

    &:hover {
      background-color: $light;
    }
  }

  .custom-rounded-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .custom-rounded-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .active {
    font-weight: $font-weight-base;
    background-color: $primary;
    color: $white;
  }

  @include tablet {
    position: static;
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
