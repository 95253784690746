.project-steps {
  display: flex;
  justify-content: center;

  .bike-transition {
    position: absolute;
    transition-duration: 2s;
    transition-timing-function: ease-out; // ease, linear, ease-in, ease-out, ease-in-out, cubic-bezier

    img {
      width: 40px;
      height: 35px;
    }
  }

  .project-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      color: lighten($primary, 10%);

      svg {
        margin-top: 4px;
      }
    }

    span {
      color: $secondary;
      font-size: $font-size-lg;
      letter-spacing: 0.69rem;
      margin: 1rem;

      @media (max-width: 499px) {
        margin: 1rem 0.7rem 0.7rem 0.7rem;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.project-preview {
  height: 350px;
  text-align: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}
