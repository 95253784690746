// Navs
// - Links

$nav-link-padding-y: 0 !default;
$nav-link-padding-x: 0 !default;
$nav-link-font-size: null !default;
$nav-link-font-weight: null !default;
$nav-link-color: $link-color !default;
$nav-link-hover-color: $link-hover-color !default;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;

// - Tabs

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

// - Pills

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// - Divider

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: $spacer / 2 !default;

// Navbar
// - Padding

$navbar-padding-y: $spacer * 0.5 !default;
$navbar-padding-x: null !default;
$navbar-nav-link-padding-x: 0.5rem !default;

// - Brand

$navbar-brand-font-size: $font-size-lg !default;
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5 !default;
$navbar-brand-margin-end: 1rem !default;

// - Toggler

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: $btn-focus-width !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;

// - Light

$navbar-light-color: $secondary !default;
$navbar-light-hover-color: $primary !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($black, 0.2) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;
$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;

// - Dark

$navbar-dark-color: rgba($white, 0.55) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
