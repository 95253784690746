.dropdown {
  .dropdown-no-arrow {
    &::before,
    &::after {
      display: none !important;
    }
  }

  .dropdown-toggle {
    color: rgba(0, 0, 0, 0.5);

    &:hover,
    &:focus {
      color: $dark;
    }
  }

  &.show {
    .dropdown-menu {
      .dropdown-item {
        border-radius: 10px;
        font-size: $font-size-sm;

        &:not(:first-child) {
          margin-top: 0.25rem;
        }

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }

        &.disabled {
          opacity: 0.5;
        }

        &:hover {
          background-color: $light;
          color: $dark;
        }
      }
    }
  }
}
