// Pagination

$pagination-bg: $white !default;
$pagination-color: $link-color !default;
$pagination-line-height: 1.25 !default;

// Disabled

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

// Padding

$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;

// Border

$pagination-border-width: $border-width !default;
$pagination-border-color: $gray-300 !default;

// Focus

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

// Hover

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

// Active

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

// Size `sm`

$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-border-radius-sm: $border-radius-sm !default;

// Size `lg`

$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-border-radius-lg: $border-radius-lg !default;
