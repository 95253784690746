@each $color, $value in $theme-colors {
  .btn {
    &-#{$color} {
      @include button-variant(
        $value,
        $value,
        $hover-background: darken($value, 6.5%),
        $hover-border: darken($value, 6.5%),
        $active-background: darken($value, 10%),
        $active-border: darken($value, 10%)
      );
      color: $white;

      &:hover,
      &:focus,
      &:active,
      &.active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $white;
      }

      &.inverse-hover {
        border-color: $white;

        &:hover,
        &:focus,
        &:active,
        &.active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          background-color: lighten($value, 6.5%);
          border-color: $white;
        }
      }
    }

    &-outline-#{$color} {
      @include button-outline-variant(
        $value,
        $color-hover: color-yiq($value),
        $active-background: $value,
        $active-border: $value
      );
    }

    &-circle {
      @include btn-circle;

      &-#{$color} {
        @include btn-circle;
        color: $value;

        &:hover,
        &:focus,
        &:active,
        &.active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          color: $value;
          border-color: $light;
          &.toogle {
            color: $light;
            border-color: $info;
          }
        }

        &:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:active,
        &.active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          border-color: $light;
          color: $value;
          &.toogle {
            color: $light;
            border-color: $info;
          }
        }
      }
    }

    &.no-border {
      border-color: transparent;
      &:hover,
      &:focus,
      &:active,
      &.active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        border-color: transparent;
      }
    }
  }
}

.btn {
  &:disabled {
    background-color: $light;
    border-color: $light;
  }
}
