// Tooltips

$tooltip-bg: $black !default;
$tooltip-color: $white !default;
$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-opacity: 0.9 !default;

// Border

$tooltip-border-radius: $border-radius !default;

// Padding

$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;

// Margin

$tooltip-margin: 0 !default;

// Arrow

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;
