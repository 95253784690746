// Examples: https://mdbootstrap.com/docs/standard/data/tables/

// Table

$table-bg: transparent !default;
$table-color: $body-color !default;
$table-accent-bg: transparent !default;
$table-th-font-weight: $font-weight-bold !default;
$table-group-separator-color: black !default;
$table-caption-color: $text-muted !default;
$table-bg-level: -8 !default; // applies to tables with variants

// Head

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

// Border

$table-border-level: -6 !default;
$table-border-factor: 0.1 !default;
$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

// Cell

$table-cell-padding: 0.75rem !default;
$table-cell-padding-y: 0.5rem !default;
$table-cell-padding-x: 0.5rem !default;
$table-cell-vertical-align: top !default;

// Striped

$table-striped-color: $table-color !default;
$table-striped-bg-factor: 0.05 !default;
$table-striped-bg: rgba($black, $table-striped-bg-factor) !default;
$table-striped-order: odd !default;

// Active

$table-active-color: $table-color !default;
$table-active-bg-factor: 0.1 !default;
$table-active-bg: rgba($black, $table-active-bg-factor) !default;

// Hover

$table-hover-color: $table-color !default;
$table-hover-bg-factor: 0.075 !default;
$table-hover-bg: rgba($black, $table-hover-bg-factor) !default;

// Size `sm`

$table-cell-padding-sm: 0.3rem !default;
$table-cell-padding-y-sm: 0.25rem !default;
$table-cell-padding-x-sm: 0.25rem !default;

// Dark

$table-dark-color: $white !default;
$table-dark-bg: lighten($dark, 15%) !default;
$table-dark-accent-bg: lighten($table-dark-bg, 10%) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($table-dark-color, 0.06) !default;
$table-dark-border-color: lighten($table-dark-accent-bg, 10%) !default;
