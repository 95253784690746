.navbar {
  z-index: 1000;
  background-color: $light;
  margin: 0 auto !important;
  position: fixed !important;
  width: 100vw;

  @include tablet {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);

    &-toggler {
      border: none !important;

      svg {
        color: white !important;
      }
    }

    &-nav {
      padding: 2rem 1rem;
    }
  }

  &-nav {
    display: flex;
    justify-content: center;
    width: 100%;

    @include desktop {
      align-items: center;
    }

    .nav-link {
      font-weight: bold;
      cursor: pointer;

      @include desktop {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      @include tablet {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.current-nav-link {
  color: $gray-600;
  border-color: $primary;
  border-bottom-style: solid;
}
