// Image thumbnails

$thumbnail-bg: $body-bg !default;
$thumbnail-padding: 0.25rem !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;

// Border

$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
