// Grid

$grid-columns: 12 !default;
$grid-row-columns: 6 !default;
$grid-gutter-width: 1.4rem !default; // Gutter width (on each side of a column)

// Variables

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// To ensure that media queries do not overlap when necessary, provide a maximum and a minimum

$sm-min: $sm;
$md-min: $md;
$lg-min: $lg;
$xl-min: $xl;

$sm-max: $sm - 1;
$md-max: $md - 1;
$lg-max: $lg - 1;
$xl-max: $xl - 1;

// Breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-ascending($container-max-widths, '$container-max-widths');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');
