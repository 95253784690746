.is-invalid {
  input,
  textarea {
    border-color: $danger;
  }
}

// TEXTAREA

.no-resizing {
  resize: none;
}
