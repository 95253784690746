.card {
  &.box {
    box-shadow: none;
    border: none;
    background: none;
    padding: 0;
    margin-bottom: 0;

    .card-header {
      align-items: center;
      background-color: $white;
      border-radius: 10px;
      border: 2px solid $white;
      display: flex;
      height: 3rem;
      margin-bottom: 5px;
    }

    .card-header.accordion-tree {
      background-color: $gray-300;

      .btn-toggle-tree {
        background-color: transparent;
        border: none;
        font-size: $font-size-md;
        color: transparentize($gray-600, 0.5);
        position: absolute;
        left: 5px;

        &.btn-focus {
          color: $gray-700;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .card-body {
      margin-bottom: 5px;
    }

    .card-body.accordion-tree {
      font-size: $font-size-base;
    }
  }

  .right-arrow {
    color: $gray-600;
    height: 35px;
    width: 35px;
    margin: 0 0 0 15px;
  }
}

.msg-empty {
  text-align: center;
  color: $gray-500;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}

.bg-opacity {
  opacity: 0.5;
}

.accordion {
  & > div:last-child {
    margin-bottom: 2rem;
  }
}
