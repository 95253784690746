.carousel {
  .carousel-inner {
    .carousel-item {
      img {
        height: 500px;

        @media (min-width: 500px) and (max-width: 991px) {
          height: 400px;
        }

        @media (min-width: 0px) and (max-width: 499px) {
          height: 200px;
        }
      }
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000000b3;
    border-color: #00000000;
    border-radius: 25px;
    border-style: solid;
    height: 25px;
    width: 25px;
  }

  .carousel-control-prev-icon {
    border-right-width: thick;
  }

  .carousel-control-next-icon {
    border-left-width: thick;
  }
}
