.web-layout-footer {
  z-index: 1000;
  background: $primary;
  bottom: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.17);
  color: $light;
  height: 85px;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 100vw !important;
  padding: 15px;
  // position: fixed !important; // Para dejar el footer fijo.
  width: 100%;
}
