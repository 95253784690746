// Dropdown

$dropdown-bg: $white !default;
$dropdown-color: $body-color !default;
$dropdown-min-width: 10rem !default;
$dropdown-spacer: 0 !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-box-shadow: $box-shadow !default;

// Padding

$dropdown-padding-x: 0.5rem !default;
$dropdown-padding-y: 0.5rem !default;

// Arrow

$caret-width: 0.3rem !default;
$caret-vertical-align: $caret-width * 0.5 !default;
$caret-spacing: $caret-width * 1.5 !default;

// Border

$dropdown-border-color: $border-color !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: $border-width !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;

// Item

$dropdown-item-padding-y: $spacer * 0.25 !default;
$dropdown-item-padding-x: $spacer * 0.75 !default;

// Header

$dropdown-header-color: darken($dropdown-color, 20%) !default;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x !default;

// Divider

$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-divider-margin-y: $spacer * 0.5 !default;

// Link

$dropdown-link-color: $dropdown-color !default;
$dropdown-link-hover-color: shade-color($dropdown-link-color, 10%) !default;
$dropdown-link-hover-bg: $gray-200 !default;
$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;
$dropdown-link-disabled-color: lighten($dropdown-color, 20%) !default;
