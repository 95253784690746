// Border

$border-color: $gray-300 !default;

// Radius

$border-radius: 0.6rem !default;
$border-radius-sm: 0.3rem !default;
$border-radius-lg: 1rem !default;
$border-radius-pill: 50rem !default;

// Width

$border-width: 1px !default;
$border-widths: () !default;
$border-widths: map-merge(
  (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
  ),
  $border-widths
);
